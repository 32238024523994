
hr {
  background: rgba(0, 0, 0, 0.2);
  margin: 0.5em 0;
  height: 1px;
}
.footer {
  background: #fff;
  padding: 50px 0 10px 0;
  margin-top: auto;
  .columns {
    margin-bottom: 50px;
  }
  p {
    padding: 5px 0;
    font-size: 1rem;
    &.heading {
      color: #000;
      font-size: 1.3rem;
      font-weight: 600;
      padding: 10px 0;
    }
  }
  a {
    color: #737373;
    &:hover {
      color: #000;
    }
  }
}
