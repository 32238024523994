body:has(.darkClass) {
  background: #333 !important;
}

#app.darkClass {
  background: #333 !important;
}

.darkClass {
  * {
    color: #f0f0f0;
  }

  .tag {
    background-color: whitesmoke;
    span {
      color: #000;
      font-weight: 600;
    }
    &.is-primary {
      span {
        color: #fff;
      }
    }
    .mdi {
      color: #000;
    }
  }

  .title,
  label.label {
    color: #f0f0f0 !important;
  }

  .card,
  .simple-card,
  .result-list,
  .card-option,
  .gray-container,
  .word {
    color: #f0f0f0 !important;
    background-color: #404040 !important;
    border-color: #636363 !important;
  }

  #navbar {
    background-color: #1a1a1a !important;

    .navbar-menu {
      background: #1a1a1a;

      a {
        color: #cccccc !important;
      }
    }
  }

  .header {
    background: #4d4d4d !important;
    .darksplit {
      background: #363636 !important;
    }
  }

  .button {
    border: 0;
    background-color: #4d4d4d;
    border-bottom-color: #191919;

    &.is-dark,
    &.is-normal,
    &.is-default {
      background-color: #4d4d4d !important;
    }

    &.is-light,
    &.is-warning,
    &.is-danger {
      color: #000 !important;
    }

    &.is-default:hover {
      background-color: #000 !important;
    }
  }

  .columns.gray {
    background: #262626 !important;
  }

  .b-skeleton {
    opacity: 0.5;
  }

  .input,
  .select select,
  .textarea,
  .select select option {
    background-color: #4d4d4d !important;
    color: #d9d9d9;

    &::placeholder {
      color: #666666;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #666666;
    }

    &::-ms-input-placeholder {
      color: #666666;
    }
  }

  .dropdown > .dropdown-trigger,
  .language-dropdown > .dropdown-trigger {
    button:hover,
    button:active,
    button:focus {
      background-color: #333;
    }
  }

  .dropdown-content {
    background-color: rgba(0, 0, 0, 0.8);
    .dropdown-item {
      color: #fff;
    }
  }

  .checkbox::before {
    background-color: #4d4d4d;
  }

  .word-component,
  .word-input > input {
    background-color: rgb(65, 65, 65) !important;
  }

  .word-input > .icon.is-clickable {
    color: #262626 !important;
  }

  .result-block {
    &.purple {
      background:
        linear-gradient(#262626, #262626) padding-box,
        linear-gradient(135deg, #595bd8, #c472ff) border-box !important;
      color: #5c5bd9 !important;
    }
    &.green {
      background:
        linear-gradient(#262626, #262626) padding-box,
        linear-gradient(135deg, #60e376, #14a92f) border-box !important;
      color: #14a92f !important;
    }
    &.red {
      background:
        linear-gradient(#262626, #262626) padding-box,
        linear-gradient(135deg, #ee4868, #db443e) border-box !important;
      color: #db443e !important;
    }
  }

  .result-component {
    background: #333 !important;
  }

  .profile-icon {
    background-color: #262626 !important;
  }

  .card.test {
    border: 2px solid #000 !important;

    textarea {
      background: #404040;
    }

    .card-footer {
      border-top: 2px solid #000;
    }
  }

  .dropdown-content,
  .dropdown-trigger {
    background-color: #4d4d4d !important;

    .dropdown-item:hover,
    .has-link a:hover {
      background: #333;
    }
  }

  .settings-content {
    background: rgb(65, 65, 65) !important;
  }

  .setting-block:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  #sidebar {
    background-color: rgb(65, 65, 65) !important;
    color: #fff;
    a {
      &:hover {
        background: #333 !important;
      }
    }
    #collapse {
      background: rgb(87, 87, 87) !important;
    }
  }

  #drop-right {
    background-color: rgba(51, 51, 51, 0.7) !important;
    a {
      &:hover {
        background: rgb(26, 26, 26) !important;
      }
    }
  }

  .hero {
    color: #f0f0f0 !important;

    &.is-light {
      background-color: #4d4d4d;
    }
  }

  .news,
  .option,
  .gray-bg {
    background: #404040 !important;
  }

  .sale {
    background: #333 !important;
  }

  .modal {
    .modal-card-body {
      background: #333;
    }

    .modal-card-head,
    .modal-card-foot {
      background-color: #000 !important;
    }
  }

  .pagination-link {
    background-color: #4d4d4d !important;
    &:hover {
      background-color: #000 !important;
      color: #f0f0f0;
    }
  }

  .footer {
    background-color: #333;
  }

  .table {
    background: #4d4d4d !important;

    th {
      color: #f0f0f0;
    }
  }

  .back {
    a {
      color: #f0f0f0 !important;
    }
  }

  .footer {
    background-color: #4d4d4d !important;

    p {
      color: #efefef !important;
    }

    p.heading {
      color: #f0f0f0 !important;
    }
  }

  .footer a {
    color: #ccc !important;
  }

  /* For topography */
  ::v-deep .mapbox-gl-draw_ctrl-draw-btn {
    color: #000 !important;
  }

  #subbar {
    background: #404040;
    border-bottom: 1px solid #5f5f5f;
  }

  .card-option-button {
    background-color: black !important;
  }

  .date-row {
    &.past,
    &.present {
      background: transparent;
    }
  }

  .card-option {
    background-color: #333 !important;
  }

  code {
    background-color: #000;
  }

  nav.tabs {
    background: #000;
    li.is-active > a {
      background: #333 !important;
    }
  }
  .result-notification {
    &.correct {
      background: #065800;
    }
    &.wrong {
      background: #580000;
    }
  }

  #list-content {
    background: #404040 !important;
  }

  #side-container #sidebar a.router-link-exact-active {
    border-color: #23b1de;
    background: #121212;
  }

  /* Studybuddy */
  #chats, #chat-subbar {
    background: #333 !important;
  }
  .chat-item.selected {
    background: #131313 !important;
  }
  .box {
    background: #4d4d4d;
    color: #f0f0f0;
  }
  .user > .box {
    background: #5483ed;
    color: #f0f0f0;
  }
}
