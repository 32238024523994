
.loading-icon {
  max-width: 50px;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
