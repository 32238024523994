
.achievement-title {
  font-size: 25px;
  margin-top: 20px;
  font-weight: 600;
}
.modal-card {
  padding-top: 50px;
  .modal-card-head {
    padding-top: 100px;
    font-weight: 600;
    flex-direction: column;
    .badge {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .modal-card-body {
    @media only screen and (max-width: 500px) {
      p {
        font-size: 0.8rem !important;
      }
    }
  }
}

.zoom-in-enter-active,
.zoom-in-leave-active {
  transition: opacity 0.5s;
  .animation-content,
  .animation-content {
    transition: transform 0.5s;
  }
}
.zoom-in-enter,
.zoom-in-leave-active {
  opacity: 0;
  .animation-content,
  .animation-content {
    transform: scale(0.2);
  }
}
