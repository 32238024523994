
.navbar {
  height: 70px;
  background: #1d73f4;
  &.white {
    background: #fff;
    .navbar-brand {
      color: #000;
    }
  }
  .container {
    padding: 0;
  }
  .logo-text {
    display: none;
  }
  .navbar-brand {
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: #fff;
    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }
  .navbar-start {
    margin: 0 auto;
    .navbar-item {
      height: 100%;
      min-width: 80px;
      text-align: center;
      padding: 0 20px;
      color: #fff;
      font-weight: 600;
      font-size: 0.9rem;
      &:hover,
      &:active,
      &:focus {
        color: #ccc;
        background: transparent;
      }
    }
  }
  .navbar-menu.is-active {
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(15px);
    padding-bottom: 30px;
    .navbar-item {
      color: #000;
      margin-top: 20px;
      text-align: left;
    }
  }
}
.environment {
  .fas {
    margin-right: 10px;
  }
}
a.dropdown-item,
.has-link a {
  text-align: left !important;
}
