.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.slide-leave-to {
  transform: translateY(-20px);
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1);
  }

  100% {
    opacity: 1;
  }
}