
#subbar {
  position: relative;
  z-index: 1;
  width: auto;
  height: 60px;
  margin-bottom: 20px;
  background: #f2f1f4;
  border-bottom: 1px solid #e7e7e7;

  .container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .profile-icon {
      display: flex;
      background-color: #fff;
      border-radius: 50px;
      width: 45px;
      height: 45px;
      align-items: center;
      justify-content: center;

      &.is-active {
        background-color: #1d73f4;
        color: #fff;
      }
    }
  }

  .field {
    margin-bottom: 0 !important;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.profile-dropdown {
  position: relative;
  z-index: 1000;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 350px;

  .avatar-background {
    transition: all 0.2s;
    background: rgb(235, 235, 235);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 100%;
    border: 2px inset #fc7234;

    &:hover {
      border: 2px solid #1d73f4 !important;
      background: rgb(206, 210, 236);
    }
  }

  .menu-list {
    li {
      height: 50px;

      ::v-deep a {
        height: 50px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        margin: 5px 0;

        .icon {
          margin-right: 1em;
        }

        &.router-link-exact-active {
          background: #1d73f4;
          color: #fff;
        }
      }
    }
  }
}
