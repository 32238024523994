
@mixin collapse {
  width: 0;
  padding: 0;
  &::v-deep .link-text {
    display: none !important;
  }
  &::v-deep .category {
    opacity: 0 !important;
  }
  &::v-deep a {
    padding: 5px 10px !important;
    .icon {
      margin: 0 auto;
    }
  }
  .subbar {
    opacity: 0;
  }
  &::v-deep .tag {
    display: none;
  }
}
#side-container {
  height: 100svh;
  width: 250px;
  position: fixed;
  z-index: 50;
  @media only screen and (max-width: 1000px) {
    width: 0;
  }
  &.sidebar-collapsed {
    width: 0;
  }
  #sidebar {
    width: 250px;
    height: 100%;
    position: fixed;
    z-index: 51;
    top: 0;
    left: 0;
    background-color: #fff;
    padding: 0 30px;
    transition: all 0.3s;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.05);
    &.sidebar-collapsed {
      box-shadow: none;
      -webkit-box-shadow: none;
      @include collapse;
      a {
        display: none;
      }
    }
    hr {
      margin: 20px auto;
      height: 2px;
      background-color: #eeeeee;
    }
    .subbar {
      height: 60px;
      display: flex;
      align-items: center;
      font-size: 2rem;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 32px;
    }
    .b-tooltip {
      width: 100%;
    }
    a {
      color: #a4a4a4;
      padding: 5px 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      height: 60px;
      width: 100%;
      font-size: 1.1rem;
      font-weight: medium;
      border: 2px solid transparent;
      transition: all 0.2s;
      &:hover {
        background: #f2f2f2;
        border-radius: 10px;
        color: #3e3e3e;
        ::v-deep .image {
          transform: scale(110%);
        }
      }
      &.router-link-exact-active {
        border-color: #23b1de;
        background: #c8f2fe;
        color: #23b1de;
        border-radius: 10px;
        font-weight: 600;
      }
      .tag {
        margin-left: 5px;
      }
    }
    .category {
      text-transform: uppercase;
      color: #c6c6c6;
      margin: 20px 0 10px 0;
      display: flex;
      align-items: center;
    }
    #collapse {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 40px;
      right: -25px;
      color: #000;
      background: #fff;
      width: 35px;
      height: 40px;
      border-radius: 10px;
      border-right: 1px solid rgb(230, 230, 230);
      &:hover {
        cursor: pointer;
      }
    }
  }
}
